<!--
 * @Description: In User Settings Edit
 * @Author: your name
 * @Date: 2019-09-10 09:43:36
 * @LastEditTime: 2019-09-29 14:49:19
 * @LastEditors: Please set LastEditors
 -->
<template>
    <div class="tableseven">
        <div class="box">
            <p class="box_tit">{{time}}&nbsp;&nbsp;销售责任书</p>
            <div class="table_tit">
                <div class="table_tit_left">
                    <!-- <p class="xinjian" @click="nianfen_xianshi_yincang=true">新建</p> -->
                    <div class="table_tit_left_nianfen">
                        年份
                        <el-select v-model="time" @change='xuanze_nianfen_biao'>
                            <el-option
                                v-for="(i,index) in year_list"
                                :key="index"
                                :label="i"
                                :value="i">
                            </el-option>
                        </el-select>
                    </div>
                </div>
                <div class="table_tit_right">
                    单位 : 元
                </div>
            </div>
            <div class="table">
                <div class="table_right">
                    <p @click="dianji_guanbi">关闭</p>
                    <!-- <p @click="dianji_baocun" :style="shifou_baocun?'background:#9a86db;border:0.01rem solid #9a86db;':'background:#B8C1CE;border:0.01rem solid #B8C1CE;'">保存</p>   shifou_baocun  true不能点false能点 -->
                    <!-- :style="shifou_baocun?'background:#B8C1CE;border:0.01rem solid #B8C1CE;':''" -->
                    <p @click="dianji_chutu" :style="time.length!=0?'background:#9a86db;color:#fff;border:0.01rem solid #9a86db;margin-right:0;':'margin-right:0;'">出图</p>
                </div>
            </div>
            <div class="table_con">
                <div class="table_con_tit">
                    <p></p>
                    <div class="xuanji">
                        <p @click="yi_er_ji(index)" v-for="(i,index) in yi_er_list" :key="index">
                            <img :src="yi_er==index?require('../../assets/huang_shixin.png'):require('../../assets/ccc_quan.png')" alt="">
                            {{i}}
                        </p>
                    </div>
                    <!-- <p></p> -->
                    <div v-if="time.length!=0" class="shaixuan_liebiao">
                        筛选<p class="shaixuan_box"></p>
                        <ul>
                            <li v-for="(i,index) in shaixuan_list" @click="i.zhi=!i.zhi" :key="index"><img :src="i.zhi?require('../../assets/okblue.png'):require('../../assets/noccc.png')" alt=""><p>{{i.name}}</p></li>
                        </ul>
                    </div>
                </div>
                <p>
                    单位：元
                </p>
                <el-table :data="neirong_list1"
                    border
                    max-height="600"
                    :cell-style="cellStyle_tiao"
                    :header-cell-style="headerCellStyle"
                    >
                    <el-table-column>
                        <template slot="header">
                            <div style="text-align:center;">
                                产品任务责任书
                            </div>
                        </template>
                        <el-table-column v-for="(i, ind) in biaotou1"
                            :key="`col_${ind}`"
                            :prop="biaotou1[ind].con"
                            :label="i.name">
                            <!-- <template slot-scope="props">
                            </template> -->
                        </el-table-column>
                    </el-table-column>
                </el-table>
                <table class="laoye-tbody" border="1">
                    <tr class="laoye-tit">
                        <td>合计</td>
                        <td style="text-align:right;padding-right:0.14rem;">{{chanpin_heji}}</td>
                    </tr>
                </table>
                <div class="table_con_tit">
                    <p></p>
                    <div class="xuanji">
                        <p @click="bumen_renyuan_dian(index)" v-for="(i,index) in bumen_renyuan_list" :key="index">
                            <img :src="bumen_renyuan==index?require('../../assets/huang_shixin.png'):require('../../assets/ccc_quan.png')" alt="">
                            {{i}}
                        </p>
                    </div>
                    <!-- <p></p> -->
                    <div v-if="time.length!=0" class="shaixuan_liebiao seclect">
                        筛选<p class="shaixuan_box"></p>
                        <ul v-show= 'bumen_renyuan == 1' style="width: 200px;">
                            <li v-for="(i,index) in shaixuan_list2" @click="i.zhi=!i.zhi" :key="index" style="width: 200px;"><img :src="i.zhi?require('../../assets/okblue.png'):require('../../assets/noccc.png')" alt=""><p style="width: 100%;">{{i.dept_name}}--{{i.name}}</p></li>
                        </ul>
                        <ul v-show= 'bumen_renyuan == 0'>
                            <li v-for="(i,index) in shaixuan_list2" @click="i.zhi=!i.zhi" :key="index"><img :src="i.zhi?require('../../assets/okblue.png'):require('../../assets/noccc.png')" alt=""><p style="width: auto;">{{i.name}}</p></li>
                        </ul>
                    </div>
                </div>
                <p>单位：元</p>
                <el-table :data="neirong_list2"
                    border
                    max-height="600"
                    :span-method="bumen_renyuan==1?arraySpanMethod3:meiyou"
                    :cell-style="cellStyle_tiao"
                    :header-cell-style="headerCellStyle"
                    >
                    <el-table-column>
                        <template slot="header">
                            <div style="text-align:center;">
                                部门任务责任书
                            </div>
                        </template>
                        <el-table-column v-for="(i, ind) in biaotou2"
                            :key="`col_${ind}`"
                            :prop="biaotou2[ind].con"
                            :label="i.name">
                        </el-table-column>
                    </el-table-column>
                </el-table>
                <table class="laoye-tbody" border="1">
                    <tr class="laoye-tit">
                        <td :style="bumen_renyuan==1?'width:33%':''">合计</td>
                        <td :style="bumen_renyuan==1?'width:33%':''" v-if="bumen_renyuan==1"></td>
                        <td :style="bumen_renyuan==1?'width:33%;text-align:right;padding-right:0.14rem;':'text-align:right;padding-right:0.14rem;'">{{ren_heji}}</td>
                    </tr>
                </table>
            </div>
        </div>
        <tuqi ref="mubiao_tu7"></tuqi>
    </div>
</template>

<script>
import tuqi from '../Figures/Figureseven'
import { generate_as_aim_resp, query_as_year_list } from '../../api/api.js'
export default {
  name: 'tableseven',
  data () {
    return {
      time: '',
      nian_sj: '',
      year_list: '',
      neirong_list1: [],
      neirong_list2: [],
      biaotou1: [],
      biaotou2: [],
      yi_er_list: ['一级', '明细'],
      yi_er: 0,
      bumen_renyuan_list: ['部门', '人员'],
      bumen_renyuan: 0,
      bumen: [],
      chanpin: [],
      chanpin2: [],
      renyuan: [],
      bumen_wz: [],
      chanpin_wz: [],
      chanpin2_wz: [],
      renyuan_wz: [],
      spanArr3: [],
      pos3: 0,
      indexArr3: [],
      shaixuan_list: [],
      shaixuan_list2: [],
      chanpin_heji: '0',
      ren_heji: '0'
    }
  },
  created () {
    this.jichu()
  },
  mounted () {
    this.$refs.mubiao_tu7.biaoge_dakai()
  },
  components: { tuqi },
  watch: {
    shaixuan_list: {
      handler: function (val, oldVal) {
        this.dongtai_zhanshi()
        this.zidong_heji()
      },
      deep: true
    },
    shaixuan_list2: {
      handler: function (val, oldVal) {
        this.dongtai_zhanshi()
        this.zidong_heji()
      },
      deep: true
    }
  },
  methods: {
    //   基础信息
    jichu () {
      query_as_year_list({
        data: {
          ent_id: this.$ent_id(),
          aim_type: '6'
        }
      }).then(res => {

        if (res.data.code == 10237) {
          const date = JSON.parse(res.data.body.data)

          if (date.length != 0) {
            this.year_list = []
            for (let i = 0; i < date.length; i++) {
              this.year_list.push(date[i] + '年')
            }
            date.forEach(it => {
              if (it == new Date().getFullYear()) {
                this.time = new Date().getFullYear() + '年'
                this.xuanze_nianfen_biao()
              }
            })
            this.nianfen_xianshi_yincang = false
          } else {
            this.nianfen_xianshi_yincang = true
          }
        } else if (res.data.code == 10238) {}
      })
    },
    // 表头颜色
    headerCellStyle (row) { // 根据报警级别显示颜色
      return 'text-align:center;color:#1A2533;width:2.5rem;'
    },
    cellStyle_tiao (row) {

      if (row.column.label == '本年目标销售额') {
        return 'color:#4C4A4D;font-size:0.12rem;text-align:right;width:2.5rem;'
      } else {
        return 'color:#4C4A4D;font-size:0.12rem;text-align:center;width:2.5rem;'
      }
    },
    // 点击出图
    dianji_chutu () {
      if (this.time.length != 0) {
        // this.chuxian='111'
        // this.quan=this.time
        // console.log(this.chuanshu)

        this.$refs.mubiao_tu7.dakai_quanping(
          {
            time: this.time,
            chutu: true,
            biaoge_nei: true
          }
        )
        // this.chutu=false
      } else {
        this.$message({
          message: '选择年份',
          type: 'warning'
        })
      }
    },
    getSpanArr3 (data) {
      for (var i = 0; i < data.length; i++) {
        if (i === 0) {
          this.spanArr3.push(1)
          this.pos3 = 0
        } else {
          // 判断当前元素与上一个元素是否相同（line为标记）
          if (data[i].dept_name === data[i - 1].dept_name) {
            this.spanArr3[this.pos3] += 1
            this.spanArr3.push(0)
          } else {
            this.spanArr3.push(1)
            this.pos3 = i
          }
        }
      }
    },
    // 处理行的合并
    arraySpanMethod3 ({ row, column, rowIndex, columnIndex }) {
      if (this.indexArr3.includes(rowIndex)) {
        if (columnIndex === 0) {
          return {
            rowspan: 1,
            colspan: 3
          }
        } else if (columnIndex === 1) {
          return {
            rowspan: 0,
            colspan: 0
          }
        }
      }
      if (columnIndex === 0) {
        const _row = this.spanArr3[rowIndex]
        const _col = _row > 0 ? 1 : 0
        return {
          rowspan: _row,
          colspan: _col
        }
      }
    },
    // 点击关闭
    dianji_guanbi () {
      this.$router.push('/aspreackeven')
    },
    meiyou () {

    },
    // 选择年份
    xuanze_nianfen_biao () {
      generate_as_aim_resp({
        data: {
          ent_id: this.$ent_id(),
          year: this.time.slice(0, 4)
        }
      }).then(res => {


        if (res.data.code == 200) {
          const chanpin = JSON.parse(res.data.body.cat_list)
          const bumen = JSON.parse(res.data.body.dept_list)
          const chanpin2 = JSON.parse(res.data.body.prod_list)
          const renyuan = JSON.parse(res.data.body.staff_list)
          this.chanpin = chanpin
          this.chanpin2 = chanpin2
          this.bumen = bumen
          this.renyuan = renyuan
          this.chanpin_wz = chanpin
          this.chanpin_wz.map(item => {
            this.$set(item, 'zhi', true)
            this.$set(item, 'name', item.cat_name)
          })
          this.chanpin2_wz = chanpin2
          this.chanpin2_wz.map(item => {
            this.$set(item, 'zhi', true)
            this.$set(item, 'name', item.prod_name)
          })
          this.bumen_wz = bumen
          this.bumen_wz.map(item => {
            this.$set(item, 'zhi', true)
            this.$set(item, 'name', item.dept_name)
          })
          this.renyuan_wz = renyuan
          this.renyuan_wz.map(item => {
            this.$set(item, 'zhi', true)
            this.$set(item, 'name', item.staff_name)
          })
          this.chushi1()
          this.spanArr3 = [],
          this.pos3 = 0,
          this.chushi2()
          if (this.yi_er == 0) {
            this.shaixuan_list = this.chanpin_wz
          } else if (this.yi_er == 1) {
            this.shaixuan_list = this.chanpin2_wz
          }
          if (this.bumen_renyuan == 0) {
            this.shaixuan_list2 = this.bumen_wz
          } else if (this.bumen_renyuan == 1) {
            this.shaixuan_list2 = this.renyuan_wz
          }
        } else if (res.data.code == 500) {}
      })
    },
    // 一级与明细点击
    yi_er_ji (index) {
      this.yi_er = index
      if (this.yi_er == 0) {
        this.chushi1()
      } else if (this.yi_er == 1) {
        this.biaotou1 = [
          {
            name: '明细产品',
            con: 'prod_name'
          },
          {
            name: '本年目标销售额',
            con: 'amt_aim_final'
          }
        ]
        this.neirong_list1 = []
        if (this.chanpin2.length != 0) {
          for (let i = 0; i < this.chanpin2.length; i++) {
            this.neirong_list1.push({
              prod_name: this.chanpin2[i].prod_name,
              amt_aim_final: Number(this.chanpin2[i].amt_aim_final).toFixed(0)
            })
          }
        }
      }
      if (this.yi_er == 0) {
        this.shaixuan_list = this.chanpin_wz
      } else if (this.yi_er == 1) {
        this.shaixuan_list = this.chanpin2_wz
      }
    },
    chushi1 () {
      this.biaotou1 = [
        {
          name: '一级产品',
          con: 'cat_name'
        },
        {
          name: '本年目标销售额',
          con: 'amt_aim'
        }
      ]
      this.neirong_list1 = []
      if (this.chanpin.length != 0) {
        for (let i = 0; i < this.chanpin.length; i++) {
          this.neirong_list1.push({
            cat_name: this.chanpin[i].cat_name,
            amt_aim: Number(this.chanpin[i].amt_aim).toFixed(0)
          })
        }
      }
    },
    //  部门与人员点击
    bumen_renyuan_dian (index) {
      this.bumen_renyuan = index
  console.log(index)
      if (this.bumen_renyuan == 0) {
        this.chushi2()
      } else if (this.bumen_renyuan == 1) {
        this.biaotou2 = [
          {
            name: '部门',
            con: 'dept_name'
          },
          {
            name: '人员',
            con: 'staff_name'
          },
          {
            name: '本年目标销售额',
            con: 'amt_aim'
          }
        ]
        this.neirong_list2 = []
        if (this.renyuan.length != 0) {
          for (let i = 0; i < this.renyuan.length; i++) {
            this.neirong_list2.push({
              dept_name: this.renyuan[i].dept_name,
              amt_aim: this.renyuan[i].amt_aim,
              staff_name: this.renyuan[i].staff_name
            })
          }
        }
        this.spanArr3 = [],
        this.pos3 = 0,
        this.getSpanArr3(this.neirong_list2)
      }
      if (this.bumen_renyuan == 0) {
        this.shaixuan_list2 = this.bumen_wz
      } else if (this.bumen_renyuan == 1) {
        this.shaixuan_list2 = this.renyuan_wz
      }
    },
    chushi2 () {
      this.biaotou2 = [
        {
          name: '部门',
          con: 'dept_name'
        },
        {
          name: '本年目标销售额',
          con: 'amt_aim'
        }
      ]
      this.neirong_list2 = []
      if (this.bumen.length != 0) {
        for (let i = 0; i < this.bumen.length; i++) {
          this.neirong_list2.push({
            dept_name: this.bumen[i].dept_name,
            amt_aim: this.bumen[i].amt_aim
          })
        }
      }
    },
    dongtai_zhanshi () {
      if (this.yi_er == 0) {
        const list = []
        for (let i = 0; i < this.shaixuan_list.length; i++) {
          if (this.shaixuan_list[i].zhi) {
            list.push(this.shaixuan_list[i])
          }
        }
        this.biaotou1 = [
          {
            name: '一级产品',
            con: 'cat_name'
          },
          {
            name: '本年目标销售额',
            con: 'amt_aim'
          }
        ]
        this.neirong_list1 = []
        for (let i = 0; i < list.length; i++) {
          this.neirong_list1.push({
            cat_name: list[i].cat_name,
            amt_aim: Number(list[i].amt_aim).toFixed(0)
          })
        }
      } else if (this.yi_er == 1) {
        const list = []
        for (let i = 0; i < this.shaixuan_list.length; i++) {
          if (this.shaixuan_list[i].zhi) {
            list.push(this.shaixuan_list[i])
          }
        }
        this.biaotou1 = [
          {
            name: '明细产品',
            con: 'prod_name'
          },
          {
            name: '本年目标销售额',
            con: 'amt_aim_final'
          }
        ]
        this.neirong_list1 = []
        for (let i = 0; i < list.length; i++) {
          this.neirong_list1.push({
            prod_name: list[i].prod_name,
            amt_aim_final: Number(list[i].amt_aim_final).toFixed(0)
          })
        }
      }
      if (this.bumen_renyuan == 0) {
        const list = []
        for (let i = 0; i < this.shaixuan_list2.length; i++) {
          if (this.shaixuan_list2[i].zhi) {
            list.push(this.shaixuan_list2[i])
          }
        }
        this.biaotou2 = [
          {
            name: '部门',
            con: 'dept_name'
          },
          {
            name: '本年目标销售额',
            con: 'amt_aim'
          }
        ]
        this.neirong_list2 = []
        for (let i = 0; i < list.length; i++) {
          this.neirong_list2.push({
            dept_name: list[i].dept_name,
            amt_aim: Number(list[i].amt_aim).toFixed(0)
          })
        }
      } else if (this.bumen_renyuan == 1) {
        const list = []
        for (let i = 0; i < this.shaixuan_list2.length; i++) {
          if (this.shaixuan_list2[i].zhi) {
            list.push(this.shaixuan_list2[i])
          }
        }
        console.log(this.shaixuan_list2, 'wrqweqwe')
        this.biaotou2 = [
          {
            name: '部门',
            con: 'dept_name'
          },
          {
            name: '人员',
            con: 'staff_name'
          },
          {
            name: '本年目标销售额',
            con: 'amt_aim'
          }
        ]
        this.neirong_list2 = []
        for (let i = 0; i < list.length; i++) {
          this.neirong_list2.push({
            dept_name: list[i].dept_name,
            amt_aim: Number(list[i].amt_aim).toFixed(0),
            staff_name: list[i].staff_name
          })
        }
        console.log(this.neirong_list2, 'asdasdasd')
        this.spanArr3 = [],
        this.pos3 = 0,
        this.getSpanArr3(this.neirong_list2)
      }
    },
    zidong_heji () {

      let chanpin_heji = ''
      let ren_heji = ''
      if (this.yi_er == 0) {
        this.neirong_list1.forEach(item => {
          chanpin_heji = Number(chanpin_heji) + Number(item.amt_aim)
        })
      } else {
        this.neirong_list1.forEach(item => {
          chanpin_heji = Number(chanpin_heji) + Number(item.amt_aim_final)
        })
      }
      this.neirong_list2.forEach(item => {
        ren_heji = Number(ren_heji) + Number(item.amt_aim)
      })

      this.chanpin_heji = chanpin_heji
      this.ren_heji = ren_heji
    }
  }
}
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.tableseven {
    min-height:7.2rem;
    background:#F4F7FD;
    padding: 0.31rem 0.31rem 0.34rem 0.31rem;
    .box{
        min-height:7.2rem;
        width:17.2rem;
        position: relative;
        .box_tit{
            font-size: 0.22rem;
            color:#1A2533;
            text-align: center;
            margin-bottom: 0.33rem;
        }
        .lishi{
            display: flex;
            align-items: center;
            position: absolute;
            right:0;
            top:0.04rem;
            font-size: 0.14rem;
            color:#4C4A4D;
            cursor: pointer;
            i{
                display: block;
                height:0.25rem;
                width:0.28rem;
                background: url('../../assets/rzhi_no.png') no-repeat;
                background-position:center center;
                margin-left: 0.1rem;
            }
            &:hover i{
                background: url('../../assets/rzhi_ok.png') no-repeat;
                background-position:center center;
            }
        }
        .table_tit{
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-top: 0.54rem;
            .table_tit_left{
                display: flex;
                align-items: center;
                .xinjian{
                    font-size: 0.14rem;
                    background:#9a86db;
                    padding: 0.03rem 0.16rem;
                    border:0.01rem solid #9a86db;
                    border-radius: 0.02rem;
                    color:#fff;
                    margin-right: 0.15rem;
                    cursor: pointer;
                }
                .table_tit_left_nianfen{
                    font-size: 0.14rem;
                    color:#4c4a4d;
                }
            }
            .table_tit_right{
                font-size: 0.1rem;
                color:#444;
            }
        }
        .table{
            display: flex;
            align-items: center;
            justify-content: flex-end;
            height:0.69rem;
            padding: 0 0.21rem;
            background:#D8E6F7;
            margin-top:0.2rem;
            div{
                display: flex;
                align-items: center;
                p{
                    padding: 0.03rem 0.16rem;
                    color:#838383;
                    font-size: 0.14rem;
                    border-radius: 0.02rem;
                    border:0.01rem solid #979797;
                    margin-right:0.12rem;
                    cursor: pointer;
                }
            }
            .table_right{
                p{
                    // &:nth-child(2){
                    //     background:#9a86db;
                    //     color:#fff;
                    //     border:0.01rem solid #9a86db;
                    // }
                    &:nth-child(1),&:nth-child(2){
                        background:#fff;
                        color:#888;
                        border:0.01rem solid #B8C1CE;
                    }
                }
            }
        }
        .table_con{
            .table_con_tit{
                display: flex;
                align-items: center;
                justify-content: space-between;
                p{
                    display: flex;
                    align-items: center;
                    font-size: 0.12rem;
                    color: #4C4A4D;
                }
                .xuanji{
                    display: flex;
                    align-items: center;
                    margin: 0.27rem 0 0.4rem 0;
                    p{
                        cursor: pointer;
                        margin-left: 0.29rem;
                    }
                    img{
                        height:0.17rem;
                        width:0.17rem;
                        margin-right: 0.07rem;
                    }
                }
                .shaixuan_liebiao{
                    display: flex;
                    align-items: center;
                    position: relative;
                    .shaixuan_box{
                        width:0.8rem;
                        height:0.3rem;
                        padding: 0 0.1rem;
                        text-align: center;
                        border:0.01rem solid #ccc;
                        border-radius: 0.03rem;
                        text-overflow: ellipsis;  /* ellipsis:显示省略符号来代表被修剪的文本  string:使用给定的字符串来代表被修剪的文本*/
                        white-space: nowrap;   /* nowrap:规定段落中的文本不进行换行   */
                        overflow: hidden; /*超出部分隐藏*/
                        margin-left: 0.1rem;
                    }
                    &:hover{
                        ul{
                            display: block;
                        }
                    }
                    ul{
                        display: none;
                        position: absolute;
                        top:0.3rem;
                        width: 1.34rem;
                        height:2.09rem;
                        z-index: 999;
                        overflow-y:auto;
                        border:0.01rem solid #ccc;
                        background: #fff;
                        &::-webkit-scrollbar {
                            width: 0.2rem;
                            height: 0.08rem;
                        }

                        &::-webkit-scrollbar-thumb {
                            border-radius: 0.12rem;
                            border: 0.06rem solid rgba(0, 0, 0, 0);
                            box-shadow: 0.08rem 0 0 #A5ADB7 inset;
                        }

                        &::-webkit-scrollbar-thumb:hover {
                            box-shadow: 0.08rem 0 0 #4A4A4A inset;
                        }
                        li{
                            padding: 0.05rem;
                            margin: 0 0.16rem;
                            display: flex;
                            align-items: center;
                            cursor: pointer;
                            &:hover{
                                background: rgb(173, 173, 173);
                            }
                            img{
                                height:0.16rem;
                                width:0.16rem;
                                margin-right: 0.03rem;
                            }
                            p{
                                font-size: 0.12rem;
                                color:#4C4A4D;
                                width: 0.9rem;
                                text-overflow: ellipsis;  /* ellipsis:显示省略符号来代表被修剪的文本  string:使用给定的字符串来代表被修剪的文本*/
                                white-space: nowrap;   /* nowrap:规定段落中的文本不进行换行   */
                                overflow: hidden; /*超出部分隐藏*/
                            }
                        }
                    }
                }
                .shaixuan_liebiao.seclect{
                   .shaixuan_box{
                        width:1rem;
                        height:0.3rem;
                        padding: 0 0.1rem;
                        text-align: center;
                        border:0.01rem solid #ccc;
                        border-radius: 0.03rem;
                        text-overflow: ellipsis;  /* ellipsis:显示省略符号来代表被修剪的文本  string:使用给定的字符串来代表被修剪的文本*/
                        white-space: nowrap;   /* nowrap:规定段落中的文本不进行换行   */
                        overflow: hidden; /*超出部分隐藏*/
                        margin-left: 0.1rem;
                    }
                    &:hover{
                      ul{
                        display: block;
                      }
                    }
    }
            }
        }
    }

    .laoye-tbody{
        border:0.01rem solid #888888;
        width:100%;
        border-collapse:collapse;
        tr{
            td{
                height:0.49rem;
                width:48%;
                text-align: center;
                line-height: 0.49rem !important;
                font-size: 0.14rem;
                color:#4C4A4D;
                position: relative;
            }
        }
    }
    @media screen and (max-width: 1600px) {
        .box {
            width:100%;
        }
    }
}
</style>
